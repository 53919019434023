<template>
  <div ref="modelWrapper">
    <transition name="fade">
      <canvas
        v-show="isLoaded"
        ref="webglCanvas"
        class="webgl"
      />
    </transition>
  </div>
</template>

<script>
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'LogoModel',
  props: {
    mousePosition: {
      type: Object,
      default: () => ({ x: null, y: null})
    },
    isMouseOn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoaded: false,
      innerRing: null,
      outerRing: null,
    };
  },
  watch: {
    mousePosition: {
      handler: function ({ x, y }) {
        if(!this.loaded) return;

        gsap.to(this.innerRing.rotation, {
          x: x / 500,
          z: y / 400,
          duration: 2,
        })
        gsap.to(this.outerRing.rotation, {
          x: - (x / 400),
          z: - (y / 600),
          duration: 2,

        })
      },
      deep: true
    },
    isMouseOn: function(newVal, oldVal) {
      if(!newVal && oldVal) {
        gsap.to(this.innerRing.rotation, {
          x: 4.6,
          z: 0,
          duration: 3,
        })
        gsap.to(this.outerRing.rotation, {
          x: 4.6,
          z: 0,
          duration: 3,
        })
      }
    }
  },
  mounted() {
    // debug
    // const gui = new dat.GUI();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderConfig({ type: 'js' });
    dracoLoader.setDecoderPath('assets/draco/');

    const gltfLoader = new GLTFLoader();
    gltfLoader.setDRACOLoader(dracoLoader);

// canvas
    const canvas = this.$refs.webglCanvas;
    const wrapperEl = this.$refs.modelWrapper;

    // Scene
    const scene = new THREE.Scene();

    let tl = gsap.timeline();

    gltfLoader.load('assets/3d-model/redsmart_logo2.glb', (gltf) => {
      const innerRing = gltf.scene.children[0];
      const outerRing = gltf.scene.children[1];

      this.innerRing = innerRing;
      this.outerRing = outerRing;


      innerRing.rotation.set(4.6, 0, 0);
      outerRing.rotation.set(4.6, 0, 0);

      outerRing.material = new THREE.MeshStandardMaterial({
        color: 0x313131,
        metalness: 0.5,
        roughness: 0.5,
      });

      innerRing.material = new THREE.MeshStandardMaterial({
        color: 0xffffff,
        metalness: 0.43,
        roughness: 0.93,
      });

      scene.add(innerRing);
      scene.add(outerRing);

      this.loaded = true

      tl.to(
        innerRing.rotation,
        {
          // x: innerRing.rotation.x + Math.PI * 2,
          // z: innerRing.rotation.z + Math.PI * 2,
          y: `+=${Math.PI * 2}`,
          duration: 16,
          repeat: -1,
          ease: 'none'
        },
        'enterRotation'
      );

      tl.to(
        outerRing.rotation,
        {
          y: `-=${Math.PI * 2}`,
          duration: 20,
          repeat: -1,
          ease: 'none'
        },
        'enterRotation'
      );

      this.isLoaded = true
    });

    const worldLight = new THREE.AmbientLight(0xffffff, 1, 2);
    const pointLight = new THREE.PointLight(0xffffff, 0.7);

    const pointLightTwo = new THREE.PointLight(0xffffff, 0.5);

    pointLight.position.set(2.7, 3.6, 3.1);
    pointLightTwo.position.set(-2.4, -2.4, 0.9);

    scene.add(worldLight);
    scene.add(pointLight);
    scene.add(pointLightTwo);
    /**
     * Sizes
     */
    const sizes = {
      width: wrapperEl.offsetWidth,
      height: wrapperEl.offsetHeight,
    };

    window.addEventListener('resize', () => {
      // Update sizes
      sizes.width = wrapperEl.offsetWidth;
      sizes.height = wrapperEl.offsetHeight;

      // Update camera
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();

      // Update renderer
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    });

    /**
     * Camera
     */
    // Base camera
    const camera = new THREE.PerspectiveCamera(
      10,
      sizes.width / sizes.height,
      0.1,
      100
    );
    camera.position.x = 0;
    camera.position.y = 0;
    camera.position.z = 40;
    scene.add(camera);

    // const cameraGui = gui.addFolder('Camera');

    // cameraGui
    //   .add(camera, 'fov')
    //   .min(0)
    //   .max(200)
    //   .step(1)
    //   .onChange(updateCamera);
    // console.log(camera.fov);
    // cameraGui
    //   .add(camera.position, 'x')
    //   .min(-5)
    //   .max(10)
    //   .step(0.01);
    // cameraGui
    //   .add(camera.position, 'y')
    //   .min(-5)
    //   .max(10)
    //   .step(0.01);
    // cameraGui
    //   .add(camera.position, 'z')
    //   .min(-5)
    //   .max(50)
    //   .step(0.01);

    function updateCamera() {
      camera.updateProjectionMatrix();
    }
    // Renderer
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      alpha: true,
      antialias: true,
    });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    // const clock = new THREE.Clock()

    const tick = () => {
      // const elapsedTime = clock.getElapsedTime()

      // Update objects
      // sphere.rotation.y = .5 * elapsedTime

      // Update Orbital Controls
      // controls.update()

      // Render
      renderer.render(scene, camera);

      // Call tick again on the next frame
      window.requestAnimationFrame(tick);
    };

    tick();
  },
};
</script>

<style lang="scss" scoped>
.webgl {
}
</style>
