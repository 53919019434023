<template>
  <div
    ref="textStripWrapper"
    class="w-full overflow-hidden bg-primary relative textStripWrapper"
    @mousemove="updateCursorPosition"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <div
      ref="cursorEl"
      class="cursorCircle hidden md:block"
      :class="showCircle && 'show'"
    />
    <div
      ref="textStrip"
      class="py-3 md:py-6 flex"
    >
      <div
        v-for="(el, i) in Array.apply(null, {length: 10} )"
        :key="i"
        class="flex items-center whitespace-nowrap"
      >
        <h2 class="text-white text-2xl md:text-5xl mx-3">
          {{ text }}
        </h2>
        <div class="w-3 h-3 bg-white rounded-full mx-3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'

export default {
  props: {
    text: {
      type: String,
      default: 'Set "text" prop'
    }
  },
  data() {
    return {
      showCircle: false,
    }
  },
  computed: {
    cursorEl() {
      return this.$refs.cursorEl;
    }
  },
  mounted() {
    const textStrip = this.$refs.textStrip;

    gsap.to(textStrip, {
      scrollTrigger: {
        trigger: textStrip,
        scrub: 1
      },
      translateX: '-10vw',
    })
    
  },
  methods: {
    updateCursorPosition(e) {
      const strip = this.$refs.textStripWrapper.getBoundingClientRect();

      const positionX = e.clientX - strip.left
      const positionY = e.clientY - strip.top

      const toCenter = this.cursorEl.offsetWidth / 2;

      gsap.to(this.cursorEl, {
        left: positionX - toCenter,
        top: positionY - toCenter,
      })
    },
    mouseEnter() {
      this.showCircle = true;
    },
    mouseLeave() {
      this.showCircle = false;
    },
  }
};
</script>

<style lang="scss">
  .textStripWrapper {
    cursor: none;
  }
  
  .cursorCircle {
    @apply absolute w-32 h-32 rounded-full;

    opacity: 0;
    transform: scale(0.3);
    transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
    z-index: 10;
    backdrop-filter: invert(1) sepia(1) grayscale(0.5);

    &.show {
      opacity: 1;
      transform: scale(1);
    }
  }
</style>
