<template>
  <div
    class="sticky top-0 processLogoWrapper w-1/2"
  >
    <transition name="fade">
      <canvas
        v-show="isLoaded"
        ref="webglCanvas"
        class="webgl webgl-process"
      />
    </transition>
  </div>
</template>

<script>
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'LogoModel',
  data() {
    return {
      isLoaded: false,
    };
  },
  mounted() {
    // debug
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderConfig({ type: 'js' });
    dracoLoader.setDecoderPath('assets/draco/');

    const gltfLoader = new GLTFLoader();
    gltfLoader.setDRACOLoader(dracoLoader);
    // canvas
    const canvas = this.$refs.webglCanvas;

    // Scene
    const scene = new THREE.Scene();

    gltfLoader.load('assets/3d-model/redsmart_logo2.glb', (gltf) => {
      const innerRing = gltf.scene.children[0];
      const outerRing = gltf.scene.children[1];

      innerRing.rotation.set(4.6, 0, 0);
      outerRing.rotation.set(4.6, 0, 0);

      outerRing.material = new THREE.MeshStandardMaterial({
        color: 0x313131,
        metalness: 0.5,
        roughness: 0.5,
      });

      innerRing.material = new THREE.MeshStandardMaterial({
        color: 0xc41432,
        metalness: 0.43,
        roughness: 0.93,
      });

      scene.add(innerRing);
      scene.add(outerRing);

      gsap.to(camera.position, {
        scrollTrigger: {
          trigger: '.process-wrapper',
          // markers: true,
          start: 'top center',
          end: '5% center',
          scrub: 2,
        },
        x: window.innerWidth < 640 ? -1.5 : -3,
        z: window.innerWidth < 640 ? 50 : 40,
        duration: 3,
      })

      gsap.to(innerRing.rotation, {
        scrollTrigger: {
          trigger: '.process-wrapper',
          start: '-30% top',
          end: '80%',
          scrub: 1,
          // pin: '.webgl-process'
        },
        y: '+=4',
      })

      gsap.to(outerRing.rotation, {
        scrollTrigger: {
          trigger: '.process-wrapper',
          start: '-30% top',
          end: '80%',
          scrub: 1,
        },
        y: '-=3',
      })
      gsap.to(camera.position, {
        scrollTrigger: {
          trigger: '.process-wrapper',
          start: '-30% top',
          end: '80%',
          scrub: 2,
        },
        y: 3,
      })

      this.isLoaded = true
    });

    const worldLight = new THREE.AmbientLight(0xffffff, 1, 2);
    const pointLight = new THREE.PointLight(0xffffff, 0.7);

    const pointLightTwo = new THREE.PointLight(0xffffff, 0.5);

    pointLight.position.set(2.7, 3.6, 3.1);
    pointLightTwo.position.set(-2.4, -2.4, 0.9);
    scene.add(worldLight);
    scene.add(pointLight);
    scene.add(pointLightTwo);

    /**
     * Sizes
     */

    const processLogoWrapper = document.querySelector('.processLogoWrapper');

    const sizes = {
      width: processLogoWrapper.offsetWidth,
      height: window.innerHeight,
    };

    window.addEventListener('resize', () => {
      // Update sizes
      sizes.width = processLogoWrapper.offsetWidth;
      sizes.height = window.innerHeight;

      // Update camera
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();

      // Update renderer
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    });

    /**
     * Camera
     */
    // Base camera
    const camera = new THREE.PerspectiveCamera(
      10,
      sizes.width / sizes.height,
      0.1,
      100
    );
    camera.position.x = -4;
    camera.position.y = -4;
    camera.position.z = 45;

    scene.add(camera);

    // Renderer
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      alpha: true,
      antialias: true,
    });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    const tick = () => {
      // Render
      renderer.render(scene, camera);

      // Call tick again on the next frame
      window.requestAnimationFrame(tick);
    };

    tick();
  },
};
</script>

<style lang="scss" scoped>
.webgl {
  // position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  outline: none;
}
</style>
