<template>
  <div class="max-w-screen-xl mx-auto contactForm px-6 sm:px-8">
    <LogoModelContact class="mx-auto" />
    <h2 class="text-3xl mb-3 mx-auto text-center">
      Leave us a message
    </h2>
    <p class="mx-auto text-center">
      Fill in the form below to drop us an email and we’ll be in touch shortly.
    </p>

    <div class="h-12 border-l border-gray-600 mx-auto w-0 my-8"></div>

    <form
      ref="contactForm"
      class="max-w-lg mx-auto"
      name="contact"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      @submit.prevent="sendContactForm"
    >
      <input
        type="hidden"
        name="form-name"
        value="contact"
      />
      <label
        for="name"
        class="label"
      >Name:</label>
      <input
        id="name"
        type="text"
        name="name"
        placeholder="eg. John Doe"
        class="input"
        required
      />

      <label
        for="email"
        class="label"
      >E-mail:</label>
      <input
        id="email"
        type="email"
        name="email"
        placeholder="eg. john.doe@email.com"
        class="input"
        required
      />

      <label
        for="message"
        class="label"
      >Message:</label>
      <textarea
        id="message"
        type="text"
        name="message"
        maxlength="3000"
        required
        rows="4"
        placeholder="Your message (up to 3000)"
        class="input"
      />
      <transition name="fade">
        <p
          v-show="isSubmitted"
          class="bg-green-50 px-6 py-4 text-green-500 rounded-md border border-green-200 text-sm text-center fadeIn"
        >
          <strong>Thank you!</strong><br />
          Your message has been sucesfully submitted!
          <br class="hidden md:block" />We will go back to you soon!
        </p>
      </transition>
      <transition name="fade">
        <p
          v-show="isError"
          class="bg-red-50 px-6 py-4 text-red-500 rounded-md border border-red-200 text-sm text-center fadeIn"
          v-html="errorMsg ? errorMsg : defaultMsg"
        >          
        </p>
      </transition>
      <div class="w-full flex justify-center mt-5">
        <VueRecaptcha
          ref="recaptcha"
          class="mx-auto"
          sitekey="6Lf9fToeAAAAAMvpwsIw1ItsUUEfFT62b3OORXXt"
          theme="light"
          @verify="recaptchaVerified = true"
          @expired="recaptchaVerified = false"
        />
      </div>

      <button
        class="btn btn-primary mt-5 mx-auto block-display"
        :disabled="submitting"
        type="submit"
      >
        SEND MESSAGE
      </button>

      <p class="text-sm text-gray-400 text-center mx-auto mt-4">
        All fields are mandatory.
      </p>
    </form>
  </div>
</template>

<script>
import LogoModelContact from '@/components/LogoModelContact';
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  components: {
    LogoModelContact,
    VueRecaptcha
  },
  data() {
    return {
      submitting: false,
      isSubmitted: false,
      isError: false,
      errorMsg: '',
      defaultMsg: '<strong>Error has occured.</strong><br /> Please refresh the website and try again.<br class="hidden md:block" /> You can try to call us on +44 (0) 23 8067 1087',
      recaptchaVerified: false,
    };
  },
  methods: {
    sendContactForm(e) {
      this.isError = false;

      if (!this.recaptchaVerified) {
        this.isError = true;
        this.errorMsg = '<p>Please veryfiy recaptcha first!</p>';
        return;
      }

      const contactForm = this.$refs.contactForm;
      const formData = new FormData(contactForm);

      formData.delete('g-recaptcha-response')

      this.isSubmitted = false;
      this.submitting = true;


      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData).toString(),
      })
        .then((e) => {
          if (e.status === 404) throw Error();
          this.isSubmitted = true;
          this.submitting = false;
          contactForm.reset();
        })
        .catch((error) => {
          this.isError = true;
          this.submitting = false;
          console.log(error);
        });
    },
  },
};
</script>
