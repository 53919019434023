<template>
  <div class="grid-bg">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div class="hidden md:block"></div>
    <div class="hidden md:block"></div>
    <div class="hidden md:block"></div>
    <div class="hidden md:block"></div>
    <div class="hidden md:block"></div>
    <div class="hidden md:block"></div>
    <div class="hidden md:block"></div>
    <div class="hidden md:block"></div>
    <div class="hidden md:block"></div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
  .grid-bg {
    @apply absolute inset-0 flex;

    div {
      @apply w-full
    }
    
    div:not(:first-of-type) {
      @apply border-l w-full h-full border-beige;
    }
  }
</style>