<template>
  <div
    class="fixed top-0 w-full max-w-screen-3xl flex pl-5 sm:pl-10 px-10 pt-10 z-50 transition-colors duration-500 navWrapper"
    :class="isScrolled && 'bgWhiteTransparent'"
  >
    <!-- LOGO -->
    <button class="mr-auto z-10 cursor-pointer logo" @click="scrollTop">
      <img src="/assets/rsa-logo.svg" class="h-14" alt="Logo" />
    </button>
    <!-- Hamburger menu -->
    <button
      class="hamburger z-20"
      :class="isMenuOpen && 'opened'"
      @click="toggleMenu"
    >
      <div class="bg-gray-700"></div>
      <div class="bg-gray-700"></div>
      <div class="bg-gray-700"></div>
    </button>
    <transition name="fade">
      <div
        v-show="isMenuOpen"
        class="menuWrapper absolute w-screen h-screen top-0 left-0 z-10"
        @click.self="toggleMenu"
      >
        <transition name="slideIn">
          <div
            v-show="isMenuOpen"
            class="navWrapper flex items-center bg-primary w-2/3 xl:w-1/2 ml-auto h-full"
          >
            <ul
              class="font-display navList text-white text-xl sm:text-2xl md:text-3xl xl:text-5xl leading-loose w-5/6 ml-auto"
            >
              <li>
                <button @click="scrollTo('header')">
                  Home
                </button>
              </li>
              <li>
                <button @click="scrollTo('.processBar')">
                  Process
                </button>
              </li>
              <li>
                <button @click="scrollTo('.solutionWrapper')">
                  About
                </button>
              </li>
              <li>
                <button @click="scrollTo('.ourBrands')">
                  Brands
                </button>
              </li>
              <li>
                <button @click="scrollTo('.contactForm')">
                  Contact
                </button>
              </li>
            </ul>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

export default {
  data() {
    return {
      isMenuOpen: false,
      isScrolled: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  methods: {
    scrollTop() {
      gsap.to(window, {
        scrollTo: 0,
        duration: 0.4,
      });
    },
    scrollTo(className) {
      gsap.to(window, {
        scrollTo: className,
        duration: 0.4,
      });
      this.toggleMenu();
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    updateScroll() {
      if (!this.isScrolled && window.scrollY > 50) {
        this.isScrolled = true;
      } else if (this.isScrolled && window.scrollY <= 50) {
        this.isScrolled = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navWrapper {
  .logo {
    @apply transition-transform duration-500;
  }

  &.bgWhiteTransparent {
    background-color: rgba(#EEEAE2, .8);
    backdrop-filter: blur(3px);

    .logo {
      transform: translateY(-15px)
    }
  }
}

.hamburger {
  @apply h-6 w-6 flex flex-col justify-between;

  div {
    @apply w-full rounded-full transition-all duration-500;

    height: 3px;

    &:last-of-type {
      @apply w-2/3 self-end;
    }
  }

  &.opened {
    div {
      @apply bg-white;
    }

    div:nth-child(1) {
      transform: translateY(5px) rotate(-45deg);
    }

    div:nth-child(2) {
      transform: translateY(-6px) rotate(45deg);
    }

    div:last-of-type {
      @apply opacity-0;
    }
  }
}

.menuWrapper {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);

  .navWrapper {
    box-shadow: -20px 0px 30px rgba(0, 0, 0, 0.1);

    // &.slideIn {
    //   @apply translate-x-0;
    // }
  }

  button {
    &:hover {
      @apply underline;
    }
  }
}

.navList {
  li {
    @apply my-2;
  }
}

@screen md {
  .navList {
    @apply flex flex-col justify-between;

    height: 50vh;
  }
}

.slideIn-enter-active,
.slideIn-leave-active {
  transition: transform 0.5s ease-in-out;
  transform: translateX(0);
}

.slideIn-enter-from,
.slideIn-leave-to {
  transform: translateX(100%);
}
</style>
