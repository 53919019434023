<template>
  <div class="asbolute ml-12">
    <div class="scrollerDot fixed bottom-10 z-30 hidden md:block">
      <button
        class="dot w-14 h-14 bg-gray-700 rounded-full relative overflow-hidden flex items-center justify-center"
        @click="scrollDown"
      >
        <IconArrowDown class="z-10"/>
        <div class="filler bg-primary absolute top-0 bottom-full left-0 right-0" ref="filler">
        </div>
      </button>
      <ExploreText class="absolute exploreText w-32 h-32"/>
    </div>
  </div>
</template>

<script>
  import { gsap } from 'gsap'
  import { ScrollTrigger } from 'gsap/ScrollTrigger'
  import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
  import ExploreText from '@/components/ExploreText';  
  import IconArrowDown from '@/components/IconArrowDown';

  gsap.registerPlugin(ScrollTrigger)
  gsap.registerPlugin(ScrollToPlugin)

  export default {
    components: {
      ExploreText,
      IconArrowDown
    },
    mounted() {
      gsap.to(this.$refs.filler, {
        scrollTrigger: {
          trigger: 'body',
          start: 'top -5%',
          scrub: 1
        },
        bottom: -2
      })

      gsap.to('.exploreText', {
        scrollTrigger: {
          trigger: 'body',
          start: 'top -5%',
          scrub: 1
        },
        rotate: 800
      })
    },
    methods: {
      scrollDown() {
        const scroll = window.scrollY + (window.innerHeight * 0.8);
        gsap.to(window, {
          scrollTo: scroll,
          duration: 0.4,
        })
        // window.scrollBy(0, scroll);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .exploreText {
    left: -36px;
    top: -36px;
    transform: rotate(10deg)
  }
</style>