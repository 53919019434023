<template>
  <div class="process-wrapper relative w-full flex items-start">
    <div class="process pl-4 sm:pl-8 md:pl-0 w-3/5 md:w-1/2 my-12 md:my-24 relative">
      <!-- head -->
      <h2 class="text-3xl md:text-4xl mb-4">
        Smart Projects
      </h2>
      <p class="leading-6 md:leading-9 text-gray-500 md:text-lg xl:text-xl">
        Agile? Lean? Scrum? Sprints? There’s a whole world of methodologies
        and processes out there. To establish successful workflows and to
        ensure clarity for stakeholders, we stick to our three core principles:
      </p>
      <!-- two rows -->
      <div class="flex flex-col md:flex-row gap-8 md:gap-10 mt-8 md:mt-12">
        <div class="border-t border-gray-700 w-full pt-8 md:pt-12">
          <h3 class="text-xl md:text-2xl mb-4">
            Communicate
          </h3>
          <p class="leading-6 md:leading-9 text-gray-500 text-sm md:text-base">
            Effective communication is the foundation of every successful
            project. Who has responsibility for what? Who knows what’s coming
            up, what preceded or if the trajectory is on track? We begin all
            projects by establishing resources for knowledge and availability,
            and creating an approval process to keep things moving as
            efficiently as possible.
          </p>
        </div>
        <div class="border-t border-gray-700 w-full pt-8 md:pt-12">
          <h3 class="text-xl md:text-2xl mb-4">
            Build
          </h3>
          <p class="leading-6 md:leading-9 text-gray-500 text-sm md:text-base">
            Projects aren’t built on the easy wins (although they can help).
            Real progress is made by tackling the hard parts. Once a framework
            for communication is built, it’s essential to tackle the big tasks.
            This allows assumptions to be tested and issues to be addressed to
            ensure there are no hidden surprises. We can save the easy bits for
            low energy periods.
          </p>
        </div>
      </div>
      <!-- single row -->
      <div class="border-t border-gray-700 w-full pt-8 mt-8 md:pt-12 md:mt-12">
        <h3 class="text-xl md:text-2xl mb-4">
          Analyse
        </h3>
        <p class="leading-6 md:leading-9 text-gray-500 text-sm md:text-base">
          Once effective communication becomes established and as the project
          progresses, it’s vital to listen to the end-user and to proactively
          anticipate how their needs might change needs. Users are the most
          valuable resource for obtaining qualitative feedback based on
          real-world application. It’s not a case of like or dislike, but
          whether there is understanding and recognition of value creation,
          which then provides the basis for continued development.
        </p>
      </div>
    </div>
    <LogoModelProcess />
  </div>
</template>

<script>
  import LogoModelProcess from '@/components/LogoModelProcess';

  export default {
    components: {
      LogoModelProcess
    }  
  }
</script>

<style lang="scss" scoped> 
  @screen md {
    .process {
      padding-left: 10vw;
    }
  }
</style>