<template>
  <div
    ref="mainWrapper"
    class="bg-primary relative pt-0 pb-10 md:py-14 md:flex items-center justify-center z-10 mainWrapper overflow-hidden solutionWrapper"
    @mousemove="mouseMoveHandler"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <GridElement class="opacity-10" />
    <img
      src="@/assets/logo_multi_bg.png"
      alt="logo background"
      class="hidden md:absolute inset-0 object-cover h-full w-full opacity-10"
    />
    <LogoModelWhite
      class="w-full md:w-1/3 h-72 sm:h-96 md:h-auto self-stretch z-10 mr-12"
      :mouse-position="mousePosition"
      :is-mouse-on="isMouseOn"
    />

    <div class="w-full md:w-2/3 max-w-2xl px-8 md:px-0 md:pr-12 md:py-32 relative">
      <h3 class="text-3xl md:text-4xl mb-4 text-white">
        Delivering future-proof solutions.
      </h3>
      <p class="leading-6 md:leading-9 text-white opacity-90 text-lg md:text-xl">
        RED Smart Applications is a tech startup based in Southampton, UK.
      </p>
      <hr class="border-white w-1/3 my-8" />
      <p class="leading-8 text-white opacity-90">
        Our experienced team of developers, designers and product managers provide a
        wide range of consumer, SME and enterprise technology products and services.
        We will help you from conception, through development, deployment and
        continuing support, to build meaningful solutions which create real,
        measurable value for you and your customers.
      </p>
    </div>
    <div
      ref="cursorEl"
      class="cursorCircle hidden md:block"
      :class="isMouseOn && 'show'"
    />
  </div>
</template>

<script>
import LogoModelWhite from '@/components/LogoModelWhite';
import GridElement from '@/components/GridElement';

import { gsap } from 'gsap'

export default {
  components: {
    LogoModelWhite,
    GridElement
  },
  data() {
    return {
      mousePosition: {
        x: null,
        y: null,
      },
      isMouseOn: false
    }
  },
  methods: {
    mouseEnter() {
      this.isMouseOn = true;
    },
    mouseLeave() {
      this.isMouseOn = false;
    },
    mouseMoveHandler(e) {
      const rectPosition = this.$refs.mainWrapper.getBoundingClientRect();
      const circle = this.$refs.cursorEl;

      const positionX = e.clientX - rectPosition.left
      const positionY = e.clientY - rectPosition.top
      
      this.mousePosition.x = positionX;
      this.mousePosition.y = positionY;

      gsap.to(circle, {
        left: positionX - (circle.offsetWidth / 2),
        top: positionY - (circle.offsetWidth / 2),
      })
    }
  }
};
</script>

<style lang="scss" scoped>
  .mainWrapper {
    cursor: none
  }
</style>
