<template>
  <div class="h-screen headerWrapper mx-auto relative">
    <NavigationBar />
    <GridElement />
    <header class="header w-full h-full flex items-center mx-auto p-5 relative">
      <div class="w-2/3 sm:w-1/3 mx-auto z-10 ">
        <h1 class="text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
          Technology <br />
          is changing.
        </h1>
        <p class="mt-2 xl:mt-5 md:text-lg lg:text-xl xl:text-2xl text-gray-500">
          How are you keeping up?
        </p>
        <button
          class="btn btn-primary mt-4 sm:mt-6"
          @click="scrollTo('.processBar')"
        >
          LEARN MORE
        </button>
      </div>
      <div class="w-1/3 self-stretch justify-self-end bg-beige"></div>
    </header>
    <LogoModelHeader class="z-10 ml-auto" />
  </div>
</template>

<script>
  import NavigationBar from '@/components/NavigationBar';
  import GridElement from '@/components/GridElement'
  import LogoModelHeader from '@/components/LogoModelHeader';
  
  import gsap from 'gsap'
  import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

  gsap.registerPlugin(ScrollToPlugin);

  export default {
    components: {
      NavigationBar,
      GridElement,
      LogoModelHeader
    },
    methods: {
      scrollTo(className) {
        gsap.to(window, {
        scrollTo: className,
        duration: 0.4,
      })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .headerWrapper {
    max-height: 1200px;
  }
</style>