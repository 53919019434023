<template>
  <div class="max-w-screen-xl mx-auto px-8 mt-16 ourBrands">
    <h2 class="text-4xl mb-5">
      Our Brands
    </h2>
    <div class="md:flex gap-12 justify-center mt-24 items-start">
      <div class="brand-card bg-white flex flex-col">
        <img
          src="@/assets/fieldfusion_img.png"
          alt="fieldfusion mockup"
          class="w-full -mt-20"
        />
        <img
          src="@/assets/ff-standard-logotype-gradient-480px.png"
          alt="fieldfusion logo"
          class="brand-card__logo mt-6 mb-6"
        />
        <p class="text-semibold">
          Field service management software.
        </p>
        <p class="leading-6 text-sm text-gray-500 mt-4 mb-1">
          Maximise your operational efficiency, empower your staff and delight
          your customers by creating a seamless link between your office, mobile
          workforce and clients.
        </p>
        <a
          class="btn btn-secondary transform translate-y-1/2 mx-auto"
          href="https://fieldfusion.io/"
          target="_blank"
          rel="noreferrer noopener"
        >
          FIND OUT MORE
        </a>
      </div>
      <div class="brand-card bg-white flex flex-col mt-24 md:-mt-24">
        <img
          src="@/assets/datamango_img.png"
          alt="fieldfusion mockup"
          class="w-full -mt-20"
        />
        <img
          src="@/assets/datamango-logo-rgb-480px.png"
          alt="datamango logo"
          class="brand-card__logo mt-5 mb-6"
        />
        <p class="text-semibold">
          If you can dream it, we can build it…
        </p>
        <p class="leading-6 text-sm text-gray-500 mt-4 mb-1">
          Trusted experts in application and software development, data
          integration, intelligent automation and cyber security.
        </p>

        <a
          class="btn btn-secondary transform translate-y-1/2 mx-auto"
          href="https://www.datamango.co.uk/"
          target="_blank"
          rel="noreferrer noopener"
        >
          FIND OUT MORE
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
  .brand-card {
    @apply p-6 pb-0 rounded-2xl;

    h3 {
      @apply text-2xl mb-1.5;
    }

    img {
      @apply object-contain;
      
      max-height: 300px;
    }

    &__logo {
      max-width: 180px;
    }
  }

  @screen md {
    .brand-card {
      @apply max-w-sm;
    }
  }
</style>
