<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="21 21 60 60">
    <line
      x1="50"
      y1="40.93"
      x2="50"
      y2="59.07"
      fill="none"
      stroke="#ffffff"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="2"
    />
    <polyline
      points="55.75 53.32 50 59.07 44.25 53.32"
      fill="none"
      stroke="#ffffff"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="2"
    />
  </svg>
</template>
